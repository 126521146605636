import cogoToast from "cogo-toast";
import { CartContext } from "@global/CartContext";
import { useState, useEffect, useContext } from "react";
import { CompareContext } from "@global/CompareContext";
import { WishlistContext } from "@global/WishlistContext";
import {
  getCartProductQuantity,
  getDiscountPrice,
  getWishCompareProduct,
} from "@utils/product";

const useProduct = (product) => {
  const { name, slug: slugName, discount, variations, arrivingSoon } = product;
  const slug = '/product/'+slugName;
  const [productSize, setProductSize] = useState("");
  const [productStock, setProductStock] = useState(0);
  const [productPrice, setProductPrice] = useState(0);
  const [productColor, setProductColor] = useState("");
  const [productQuantity, setProductQuantity] = useState(1);
  const [productMaterial, setProductMaterial] = useState("");
  const [productColorImage, setProductColorImage] = useState("");
  const [modalQuickView, setModalQuickView] = useState(false);
  const [modalCartAdded, setModalCartNotification] = useState(false);

  const { shoppingCart } = useContext(CartContext);
  const { compareList, addToCompare, removeFromCompare } =
    useContext(CompareContext);
  const { wishlist, addToWishlist, removeFromWishlist } =
    useContext(WishlistContext);

  const isInWishlist = getWishCompareProduct(wishlist, product);
  const isInCompareList = getWishCompareProduct(compareList, product);
  const cartProductQuantity = getCartProductQuantity(
    shoppingCart,
    product,
    productColor,
    productSize
  );

  const discountedPrice = getDiscountPrice(productPrice, discount);

  const productColorHandler = (e) => {
    e.preventDefault();
    const color = e.target.dataset.colorname
      ? e.target.dataset.colorname
      : e.target.value;
    setProductColor(color);

    const image = e.target.dataset.colorthumb;
    setProductColorImage(image);

    const sizes =
      variations &&
      variations.filter((item) => item.color.name === color)[0].sizes;
    setProductSize(sizes[0].name);
    setProductStock(sizes[0].stock);

    const materials =
      variations &&
      variations.length &&
      variations.filter((item) => item.color.name === color)[0].materials;
    setProductMaterial(materials[0].slug);
    setProductPrice(materials[0].price);
    setProductQuantity(1);
  };

  const productSizeHandler = (e) => {
    e.preventDefault();
    const size = e.target.dataset.size ? e.target.dataset.size : e.target.value;
    setProductSize(size);

    const sizes =
      variations &&
      variations.filter((item) => item.color.name === productColor)[0].sizes;
    const stock = sizes.filter((item) => item.name === size)[0].stock;
    setProductStock(stock);
    setProductQuantity(1);
  };

  const productMaterialHandler = (e) => {
    e.preventDefault();
    const material = e.target.dataset.materialname
      ? e.target.dataset.materialname
      : e.target.value;
    setProductMaterial(material);

    const materials =
      variations &&
      variations.filter((item) => item.color.name === productColor)[0]
        .materials;
    const price = materials.filter((item) => item.slug === material)[0].price;
    setProductPrice(price);
  };

  const productColorImageChange = (e) => {
    e.preventDefault();
    const image = e.target.colorthumb;
    setProductColorImage(image);
  };

  const wishlistHandler = (event) => {
    event.preventDefault();
    !isInWishlist ? addToWishlist(product) : removeFromWishlist(product);
    !isInWishlist
      ? cogoToast.success(
          `"${name}" se agrego satisfactoriamente a favoritos.`,
          {
            position: "bottom-right",
            heading: "Se agrego a favoritos!",
            hideAfter: 2,
          }
        )
      : cogoToast.warn(`"${name}" fue removido de favoritos.`, {
          position: "bottom-right",
          heading: "Removido de favoritos!",
          hideAfter: 2,
        });
  };

  const compareHandler = (event) => {
    event.preventDefault();
    !isInCompareList ? addToCompare(product) : removeFromCompare(product);
    !isInCompareList
      ? cogoToast.success(`"${name}" se agrego satisfactoriamente.`, {
          position: "bottom-right",
          heading: "Agregado para comparar!",
          hideAfter: 2,
        })
      : cogoToast.warn(`"${name}" fue removido de la comparacion.`, {
          position: "bottom-right",
          heading: "Se removio de la comparacion!",
          hideAfter: 2,
        });
  };

  const modalCartAddedHandler = () =>
    setModalCartNotification((prevState) => !prevState);
  const modalQuickViewHandler = () =>
    setModalQuickView((prevState) => !prevState);
    const productQuantityIncrement = () => {
      setProductQuantity((prevState) => {
        if (prevState < productStock - cartProductQuantity) {
          return prevState + 1;
        } else {
          return prevState;
        }
      });
    };
  const productQuantityDecrement = () =>
    setProductQuantity((prevState) => (prevState > 1 ? (prevState -= 1) : 1));

  useEffect(() => {
    setProductColor(variations && variations[0]?.color?.name);
    setProductSize(variations && variations[0]?.sizes[0]?.name);
    setProductStock(
      variations && variations.length
        ? variations[0]?.sizes[0]?.stock
        : product.stock
    );
    setProductMaterial(
      variations && variations.length && variations[0]?.materials[0]?.slug
    );
    setProductPrice(
      variations && variations.length
        ? variations[0]?.materials[0]?.price
        : product.price
    );
  }, [product]);

  return {
    slug,
    arrivingSoon,
    productSize,
    productStock,
    productPrice,
    productColor,
    modalCartAdded,
    modalQuickView,
    discountedPrice,
    productQuantity,
    productMaterial,
    productColorImage,
    setProductQuantity,
    compareHandler,
    wishlistHandler,
    productSizeHandler,
    productColorHandler,
    modalCartAddedHandler,
    modalQuickViewHandler,
    productMaterialHandler,
    productColorImageChange,
    productQuantityDecrement,
    productQuantityIncrement,
  };
};

export default useProduct;
